import React, { useEffect,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";

const Download = () => {
  const dispatch = useDispatch();
  const [refresh,setRefresh] = useState(true);
  const {
    downloads: { downloads },
  } = useSelector((obj) => obj);

  useEffect(() => dispatch(actions.getdownload()), []);
    useEffect(() => {
      dispatch(actions.getdownload());
    }, [refresh]);

  return (
    <div className="container mt-2 ">
      <div className="row mb-2">
        <div className="col-md-12 col-lg-12">
          <div
            className="btn btn-success btn-sm mr-3"
            onClick={() => {
              dispatch(actions.download(1));
              dispatch(actions.getdownload());
              setRefresh(!refresh);
            }}
          >
            Generate Today's Zip File
          </div>

          <div
            className="btn btn-warning btn-sm"
            onClick={() => {
                dispatch(actions.download(0));
              dispatch(actions.getdownload());
              setRefresh(!refresh)  
            }}
          >
            Generate Previou's days Zip File
          </div>
        </div>
      </div>
      <div className="row table-responsive">
        <table className="table table-sm">
          <tr>
            <th>{`File Name`}</th>
            <th>{`File Size`}</th>
            <th>{`Download`}</th>
          </tr>
          {downloads &&
            downloads.map((item) => (
              <tr>
                <th>
                  <a href={`https://apiqa.controla.in/${item.path}`}>
                    {item.filename}
                  </a>
                </th>
                <td>{`${item.filesize} MB`}</td>
                <td>
                  {" "}
                  <a href={`https://apiqa.controla.in/${item.path}`}>
                    <i className="fa fa-file-archive-o" />
                  </a>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};

export default Download;
