import React, { Component } from "react";
import MetaTags from "react-meta-tags";

// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";

import { connect } from "react-redux";
import CovidArtGallery from "./covidartgallery";
import * as actions from "../actions";

const _photos= [
  {
    src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    width: 1,
    height: 1
  },
  {
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
    width: 4927,
    height: 1000
  },
  {
    src: "https://source.unsplash.com/qGQNmBE7mYw/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/NuO6iTBkHxE/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/pF1ug8ysTtY/600x400",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/A-fubu9QJxE/800x533",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/5P91SF0zNsI/740x494",
    width: 4,
    height: 3
  }
];


class ArtGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      viewerIsOpen: false,
    };
  }
  componentDidMount() {
    this.props.getartwork();
  }

  render() {
    return (
      <div className="container-fluid">
        <MetaTags>
          <title>Fight COVID-19 Yoga Competition</title>
          <meta
            id="meta-description"
            name="description"
            content="Fight against covid-19, express yourself in form of art. Invitation to all age groups and professionals"
          />
          <meta
            id="og-title"
            property="og:title"
            content="Fight COVID-19 Art Competition"
          />
          <meta
            id="og-image"
            property="og:image"
            content="http://donation.unitedwaybaroda.org/resources/images/artworkbanner_1.jpg"
          />
          <meta property="og:image:width" content="1600" />
          <meta property="og:image:height" content="900" />
        </MetaTags>
        {/* <div className="row">
          {this.props.artworks.map((item, j) => {
            return (
             
              <div className="col-md-3">
                <a href={"https://apiqa.controla.in/" + item.imagepath}>
                  <img
                    src={"https://apiqa.controla.in/" + item.imagepath}
                    alt={item.imagepath}
                    className="img-fluid img-thumbnail rounded"
                    //   href={"https://apiqa.controla.in/" + item.imagepath}
                  />
                  <p className="legend">{item.name}</p>
                </a>
              </div>
            );
          })}
        </div> */}
        <div className="row">
          <CovidArtGallery photos={this.props.artworks} />
        </div>
        {/* <Gallery
          photos={this.props.artworks}
          onClick={this.}
        /> */}
        {/* <ModalGateway>
          {this.state.viewerIsOpen ? (
            <Modal
              onClose={this.setState((prev) => ({
                viewerIsOpen: !prev.viewerIsOpen,
              }))}
            >
              <Carousel
                currentIndex={this.state.currentIndex}
                views={this.props.artworks.map((x) => ({
                  ...x,
                  srcset: x.src,
                  caption: x.name,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway> */}
      </div>
    );
  }
}

function mapStateToProps({ artworks }) {
  return { artworks };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getartwork: () => dispatch(actions.getartworks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArtGallery);
