import {
  GET_COVID_INFO,
  GET_DONORS,
  GET_IMPACT,
  INSERT,
  GET_ARTWORKS,
  GET_DONOR_LIST,
  GET_DOWNLOADS,
} from "../actions/types";
import donorlist from "../resources/contributors.json";
const initialState = {
  summary: [],
  regional: [],
  donors: donorlist,
  impact: [],
  insert: "",
  artworks: [],
  donorslist: [],
  downloads: [],
};
export const covidInfo = function (state = initialState, action) {
  switch (action.type) {
    case GET_COVID_INFO:
      return {
        ...state,
        ...action.data.data,
        summary: [action.data.data.summary],
        ...action.data,
      };
    default:
      return state;
  }
};

export const coviddonors = function (state = initialState.donors, action) {
  switch (action.type) {
    case GET_DONORS:
      return {
        donors: [...donorlist, ...action.data],
      };
    default:
      return { donors: initialState.donors || [] };
  }
};

export const impact = function (state = initialState.impact, action) {
  switch (action.type) {
    case GET_IMPACT:
      return action.data;
    default:
      return state;
  }
};
export const insert = function (state = initialState.insert, action) {
  switch (action.type) {
    case INSERT:
      return action.data;
    default:
      return state;
  }
};

export const artworks = function (state = initialState.artworks, action) {
  switch (action.type) {
    case GET_ARTWORKS:
      return action.data;
    default:
      return state;
  }
};

export const donorList = function (state = initialState.donorslist, action) {
  switch (action.type) {
    case GET_DONOR_LIST:
      return {
        ...state,
        donorslist: [...action.data],
      };
    default:
      return state;
  }
};

export const downloads = function (state = initialState.downloads, action) {
  switch (action.type) {
    case GET_DOWNLOADS:
      return {
        ...state,
        downloads: [...action.data],
      };
    default:
      return state;
  }
};
