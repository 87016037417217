import React from "react";
class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.getTimeDifference(this.props.eventDate);
    setInterval(() => this.getTimeDifference(this.props.eventDate), 1000);
  }

  leadingZero(num) {
    return num < 10 && num > 0 ? "0" + num : num;
  }

  getTimeDifference(eventDate) {
    const time = Date.parse(eventDate) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds });
  }

  render() {
    return (
      <div className="timer">
        <div className="App-title">
          <b>{this.props.eventName}</b> starts in
        </div>
        {/* <div className="clock">
          {this.leadingZero(this.state.days)}{" "}
          {this.state.days == 1 ? "day" : "days"}
        </div>
        <div className="clock">
          {this.leadingZero(this.state.hours)}{" "}
          {this.state.hours == 1 ? "hour" : "hours"}
        </div>
        <div className="clock">
          {this.leadingZero(this.state.minutes)}{" "}
          {this.state.minutes == 1 ? "minute" : "minutes"}
        </div>
        <div className="clock">
          {this.leadingZero(this.state.seconds)}{" "}
          {this.state.seconds == 1 ? "second" : "seconds"}
        </div> */}

        <div className="clock">
          <div className="clock__display">
            <SvgCircle
              className="clock__circle"
              max={365}
              done={this.state.days}
            />
            <div className="clock__text clock__text--days">
              <span className="clock__amount">
                {this.leadingZero(this.state.days)}
              </span>
              <span className="clock__unit">days</span>
            </div>
          </div>
          <div className="clock__display">
            <SvgCircle max={24} done={this.state.hours} />
            <div className="clock__text clock__text--hours">
              <span className="clock__amount">
                {this.leadingZero(this.state.hours)}
              </span>
              <span className="clock__unit">hours</span>
            </div>
          </div>
          <div className="clock__display">
            <SvgCircle max={60} done={this.state.minutes} />
            <div className="clock__text clock__text--minutes">
              <span className="clock__amount">
                {this.leadingZero(this.state.minutes)}
              </span>
              <span className="clock__unit">minutes</span>
            </div>
          </div>
          <div className="clock__display">
            <SvgCircle max={60} done={this.state.seconds} />
            <div className="clock__text clock__text--seconds">
              <span className="clock__amount">
                {this.leadingZero(this.state.seconds)}
              </span>
              <span className="clock__unit">seconds</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Timer;

const SvgCircle = (props) => {
  let { className, done, max, radius, stroke, strokeWidth } = props;

  radius=72;
stroke='#e91e63';
strokeWidth=8;
  let size = (radius + strokeWidth) * 2;
  let length = Math.ceil(2 * radius * Math.PI);
  let remainingLength =
    length - Math.ceil(2 * radius * Math.PI) * (done / max);
 
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle
          className="circle"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke={stroke}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          className="circle--bg"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke="rgba(0, 0, 0, .1)"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </svg>
  );
};
