import React, { Component } from "react";
import dateFormat from "dateformat";
import ReactToPrint from "react-to-print";
import logo from "../resources/images/logo.jpeg";
import { connect } from "react-redux";
import * as actions from "../actions";

class PrintReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      showinvoice: false,
      modelOpen1: false,
      loader: false,
      Invoicetoprint: [1, 2],
      productinfo: {
        name: "",
        panno: "",
      },
      amountinwords: "",
    };
  }

  componentDidMount() {


    this.convertNumberToWords(5000);

    this.props.print();
    // var search = this.props.location.search.substring(1);
    // console.log(this.props.location);
    // var objresponse = JSON.parse(
    //   '{"' +
    //     decodeURI(search)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"') +
    //     '"}'
    // );

    // let amountinwords = this.convertNumberToWords(objresponse.amount);
    // var productinfo = JSON.parse(objresponse.productinfo);
    // console.log(objresponse, productinfo);
    // this.setState({ response: objresponse, productinfo, amountinwords }, () => {
    //   this.setState({ showinvoice: true });
    //   this.setState({ refresh: !this.state.refresh });
    // });
    // if (objresponse.status === "success") {
    //   this.setState({ showinvoice: true });
    // }
  }
  onCloseModal = () => {
    this.setState({ modelOpen1: false });
  };
  printInvoice = async (row) => {
    // console.log("print row", row);
    await this.setState({ loader: true });
    await this.props.getAllPurchaseForm({
      agentId: this.state.response.udf2,
      batchCode: this.state.response.udf3,
    });
  };

  componentRef = [];
  convertNumberToWords_1(amount) {
    var words = [];
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      var received_n_array = [];
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          if (n_array[i] === 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "Rupees ";
      for (var i = 0; i < 9; i++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value !== 0) {
          words_string += words[value] + " ";
        }
        if (
          (i === 1 && value !== 0) ||
          (i === 0 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i === 3 && value !== 0) ||
          (i === 2 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i === 5 && value !== 0) ||
          (i === 4 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i === 6 &&
          value !== 0 &&
          n_array[i + 1] !== 0 &&
          n_array[i + 2] !== 0
        ) {
          words_string += "Hundred and ";
        } else if (i === 6 && value !== 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ") + "only";
    }

    // this.setState({ amountinwords: words_string });
    return words_string;
  }

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
       let value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string.concat(" only");
}

  render() {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-success btn-sm no_print">
              <i className="fa fa-print"></i>
              {" Print ALL"}
            </button>
          )}
          content={() => this.componentRefAll}
        />
        <div className="container-fluid" ref={(el) => (this.componentRefAll = el)}>
          {this.props.donorslist &&
            this.props.donorslist.map((item, index) => {
              let tran = JSON.parse(item.transactionresponse);
              let pinfo = JSON.parse(tran.productinfo);
              //console.log(tran);
              return (
                <>
                  <div
                    className="card"
                    ref={(el) => (this.componentRef[index] = el)}
                  >
                    <div className="card-body">
                      <table className="table table-borderless table-responsive">
                        <tr>
                          <td>
                            <img
                              src={logo}
                              className="rounded float-left smallimage"
                              alt={"united way"}
                            ></img>
                          </td>
                          <td>
                            <center>
                              <b>United Way of Baroda</b>
                              <p>
                                9th Floor Sidcup Towers, Race
                                Course,Vadodara-390007
                                <p>
                                  {
                                    "(Deduction & Donation allowed under Section 80-G(5) of I.T. Act. 1961)"
                                  }
                                  <p>
                                    {
                                      "(Ref. BRD/CIT-1/Main/(135)(66)/2007-2008 dated 25-06-2008valid till 31-03-2012 and"
                                    }
                                    <p>
                                      {
                                        "stands extended in perpetuity unless withdrawn-CBDT Circular No.7/2010 Dated27-10-2010)"
                                      }
                                    </p>
                                  </p>
                                </p>
                              </p>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <center>
                              <b>RECEIPT NO : {item.rno}</b>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <table className="table">
                              <tr>
                                <p>
                                  {"Received with thanks from Mr/Mrs/Ms/M/s. "}
                                  <b>{pinfo.name} </b>
                                </p>
                                <p>
                                  {"the sum of Rupees "}
                                  <b>
                                    {this.convertNumberToWords(
                                      item.transactionamount
                                    )}
                                  </b>
                                </p>
                                <p>
                                  {"by Cash/Cheque/e-banking "}
                                  <b>{tran.payuMoneyId}</b>
                                  {" Dated "}
                                  <b>
                                    {dateFormat(
                                      tran.addedon,
                                      "dd/mm/yyyy"
                                    )}
                                  </b>
                                </p>
                                <p>{"Drawn on the KVB Bank"}</p>
                                <p>
                                  {"PAN No. of Donor "}
                                  <b>{pinfo.panno||'BCCPK6158C'}</b>
                                </p>
                              </tr>
                              <tr>
                                <p>{"As Donation/Corpus Donation"}</p>
                              </tr>
                              <tr>
                                <p>
                                  <b>
                                    {"Rs. "}
                                    {item.transactionamount}
                                  </b>
                                </p>
                              </tr>
                              <tr>
                                <p>
                                  <br></br>
                                  {"PAN : AAATU0414E"}
                                </p>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-success btn-sm no_print">
                        <i className="fa fa-print"></i>
                        {" Print Receipt"}
                      </button>
                    )}
                    content={() => this.componentRef[index]}
                  />
                </>
              );
            })}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    print: () => dispatch(actions.getdonors()),
  };
};

function mapStateToProps({ donorList: { donorslist } }) {
  return { donorslist };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintReceipt);
