import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
//import { Carousel } from "react-responsive-carousel";
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//const dateFormat = require("dateformat");
import dateFormat from "dateformat";
import randomstring from "randomstring";
import coronaimage from "../resources/images/corona.png";
import healimage from "../resources/images/heal.png";
import deathimage from "../resources/images/death.png";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      imageList: [
        // "../resources/images/1.jpeg",
        // "../resources/images/2.jpeg",
        "../resources/images/10.jpg",
        "../resources/images/9.jpg",
        "../resources/images/8.jpg",
        "../resources/images/25.jpg",
        //"../resources/images/7.jpeg",
        "../resources/images/23.jpg",
        "../resources/images/6.jpeg",
        //"../resources/images/10.jpg",
        // "../resources/images/11.jpg",
      ],
      paraPoints: [
        "All donations in India are eligible for tax exemption under section 80G of Income Tax Act of India.",
        "In the recent notification Ministry of Corporate Affairs has made grants to COVID 19 as an eligible CSR activity.",
        "United Way of Baroda is positioned to work with corporate partners to customize COVID-19 response as per the requirements.",
        "Corporate partners will receive dedicated support to enable funding for COVID 19 and customize reports.",
      ],
      donations: [
        {
          fund: "Protective Kit for Healthcare Staff",
          description:
            "Disposable Surgical coverall, surgeon’s cap, 3-layer face mask, Safety googles, Surgical sterile gloves, shoe cover, drape sheet, Bio hazard bag ",
          cost: "INR 2700/kit",
          imagesrc: "../resources/images/ppekits.jpg",
        },
        {
          fund: "Ration Kits for needy community",
          description:
            "Wheat Flour 5kgs, Khichdi Rice 5kg, Tuvar Dal 2kg, Oil 2 litres, Tea powder 250gms, Chilli powder 250 gms,  Salt 1kg and Sugar 1kg, Packing Kit and Transport",
          cost: "INR 1000/kit/family",
          imagesrc: "../resources/images/Food_kit_kerala_650.jpg",
        },
        {
          fund: "Equipping designated Covid and Government hospitals",
          description:
            "Various medical equipment requirements like Monitors, Covid testing kits, Masks, Sanitizers, suction machine, Ventilators etc...",
          cost: "INR 500/kit/family",
          imagesrc: "../resources/images/testingkit.jpg",
        },
        // {
        //   fund: "Provision of handwashing machines ",
        //   description:
        //     "Portable foot-operated or sensor-based handwashing and sanitizer dispensers at key locations in hospitals and community ",
        //   cost: "INR 100/meal",
        //   imagesrc: "../resources/images/sensor.jpg",
        // },
        {
          fund: "Support For Education",
          description:
            "Support continuation of School Education for Children of bereaved families during the Covid-19 pandemic situation",
          cost: " ",
          imagesrc: "../resources/images/Miss.png",
        },
      ],
      amount: [
        { amount: 500, selected: 1 },
        { amount: 1000, selected: 0 },
        { amount: 2000, selected: 0 },
        { amount: 5000, selected: 0 },
        { amount: 10000, selected: 0 },
        { amount: 25000, selected: 0 },
        { amount: 50000, selected: 0 },
      ],
      impact: [
        {
          count: 25750,
          heading: "Beneficiaries",
          imgsrc: "../resources/images/bene.png",
        },
        {
          count: 2500000,
          heading: "Donation to PMcare Fund",
          imgsrc: "../resources/images/donationimpact.png",
        },
        {
          count: 1000,
          heading: "PPE Kits",
          imgsrc: "../resources/images/ppekit.png",
        },
        {
          count: 6500,
          heading: "Hot meals",
          imgsrc: "../resources/images/hot meal 1.png",
        },
        {
          count: 2000,
          heading: "Shoe covers for Healthcare staffs",
          imgsrc: "../resources/images/blue-shoe-cover-png-1.png",
        },
        {
          count: 2250,
          heading: "Family Ration kit",
          imgsrc: "../resources/images/rationkits.jpg",
        },
        // {
        //   count: 0,
        //   heading: "Awareness at community levels",
        //   imgsrc: "../resources/images/donationimpact.png",
        // },
      ],
      //selectedAmount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.props.getLatestCovidInfo();
      await this.props.getDonorList();
    } catch (ex) {
      console.log(ex);
    }
  }

  selectAmount(item) {
    let tmp = [...this.state.amount.map((obj) => ({ ...obj, selected: 0 }))];

    tmp = tmp.map((el) =>
      el.amount === item.amount ? { ...el, selected: 1 } : el
    );
    this.setState((prev) => ({
      amount: tmp,
      selectedAmount: item.amount,
      refresh: !prev.refresh,
    }));
  }

  onchange = (e) => {
    this.setState({ selectedAmount: e.target.value });
    let tmp = [...this.state.amount.map((obj) => ({ ...obj, selected: 0 }))];

    this.setState((prev) => ({
      amount: tmp,
      //  selectedAmount: e.target.value,
      refresh: !prev.refresh,
    }));
  };

  onInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onNextClick = () => this.setState({ showForm: true });

  paymentClick = () => {
    var batch = randomstring.generate({ length: 9, charset: "numeric" });
    const formdata = {
      name: this.state.firstname + " " + this.state.lastname,
      batchno: batch,
      contact: this.state.contact,
      email: this.state.email,
    };

    // await this.props.generatelicencekeys(formdata);

    let orderInfo = {};
    let payRequest = {};
    orderInfo = {
      name: this.state.firstname + " " + this.state.lastname,
      panno: this.state.panno,
      email: this.state.email,
      contact: this.state.contact,

      product: "COVID-19 Donation",
    };

    payRequest = {
      id: 0,
      vpc_Amount: parseFloat(this.state.selectedAmount),
      //vpc_Amount:1,
      vpc_MerchTxnRef:
        formdata.name + "|" + formdata.contact + "|" + orderInfo.product,
      vpc_OrderInfo: JSON.stringify(orderInfo),
      vpc_Version: 1,
      vpc_OrderInfo1: JSON.stringify(orderInfo),
      vpc_firstname: this.state.firstname,
      vpc_email: this.state.email,
      vpc_phone: this.state.contact,
      vpc_udf1: "COVID-19 Donation",
      vpc_udf2: this.state.panno,
      vpc_udf3: batch,
      vpc_udf4: this.state.contact,
      vpc_udf5: "",
      vpc_udf6: "2",
      vpc_lastName: this.state.lastname,
      vpc_address: this.state.address,
      formdata,
    };

    this.props.payment(payRequest);
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <fieldset>
              <legend>
                <div className="alert-custom alert-orange">LIVE UNITED</div>
              </legend>
              <div className="row">
                {this.state.imageList.map((item, index) => {
                  return (
                    <div className="col-lg" key={index}>
                      <img
                        src={item}
                        className="img-fluid img-thumbnail rounded"
                        alt="..."
                        key={index}
                      />
                    </div>
                  );
                })}
              </div>
            </fieldset>

            <div className="alert-custom alert-blue">
              UNITED WE FIGHT. UNITED WE WIN.
            </div>
            <hr></hr>
            {this.props.covidInfo.summary.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-md">
                    <div className="alert alertnomargin">
                      <h6 className="alert-heading">{"COVID-19 India"}</h6>
                      <small>
                        {" "}
                        as on :{" "}
                        {dateFormat(
                          Date(this.props.covidInfo.lastRefreshed),
                          "dd/mm/yyyy hh:mm:ss TT"
                        )}
                      </small>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="alert alertnomargin alert-info alertbackground">
                      <h4 className="alert-heading">{item.total}</h4>
                      <span>{"Active Cases"}</span>
                      <img
                        src={coronaimage}
                        alt="corona"
                        className="backgroundimage"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="alert alertnomargin alert-success alertbackground">
                      <h4 className="alert-heading">{item.discharged}</h4>
                      <span>{"Discharged"}</span>
                      <img
                        src={healimage}
                        alt="corona"
                        className="backgroundimage"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="alert alertnomargin alert-danger alertbackground">
                      <h4 className="alert-heading">{item.deaths}</h4>
                      <span>{"Deaths"}</span>
                      <img
                        src={deathimage}
                        alt="corona"
                        className="backgroundimage"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <hr></hr>
            {this.props.covidInfo.regional
              .filter((s) => s.loc === "Gujarat")
              .map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-md">
                      <div className="alert alertnomargin">
                        <h6 className="alert-heading">{"COVID-19 Gujarat"}</h6>
                        <small>
                          {" "}
                          as on :{" "}
                          {dateFormat(
                            Date(this.props.covidInfo.lastRefreshed),
                            "dd/mm/yyyy hh:mm:ss TT"
                          )}
                        </small>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="alert alertnomargin alert-info alertbackground">
                        <h4 className="alert-heading">
                          {item.confirmedCasesIndian}
                        </h4>
                        <span>{"Active Cases"}</span>
                        <img
                          src={coronaimage}
                          alt="corona"
                          className="backgroundimage"
                        />
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="alert alertnomargin alert-success alertbackground">
                        <h4 className="alert-heading">{item.discharged}</h4>
                        <span>{"Discharged"}</span>
                        <img
                          src={healimage}
                          alt="corona"
                          className="backgroundimage"
                        />
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="alert alertnomargin alert-danger alertbackground">
                        <h4 className="alert-heading">{item.deaths}</h4>
                        <span>{"Deaths"}</span>
                        <img
                          src={deathimage}
                          alt="corona"
                          className="backgroundimage"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-lg">
                <Link to="/donate" className="btn btn-info fullwidth">
                  <i className="fa fa-heartbeat " />
                  <span className="menu-title">{" Donate Now"}</span>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="alert alertcontributors">
                <div className="alert-heading ">
                  <h5>LATEST CONTRIBUTORS</h5>
                </div>
                <hr className="contrihr"></hr>
                <div className="contrisection">
                  {this.props.donors.map((item) => (
                    <h6 key={item.donor}>{item.donor}</h6>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="alert alert-orange">
          <span>
            {" "}
            <h5>
              APPEAL TO SUPPORT FOR IMMEDIATE NEEDS - #UNITEDAgainstCOVID-19
            </h5>{" "}
          </span>
        </div>
        {/* <div className="card">
          <ul className="list-group list-group-flush">
            {this.state.paraPoints.map((item, index) => {
              return (
                <li className="list-group-item" key={index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div> */}
        <div className="row padding1rem">
          {this.state.donations.map((item, index) => {
            return (
              <div
                className="margin1rem card col-md-3 colororange" //cardbackgroundimageyellow
                key={index}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 center  ">
                      <img
                        className="img-responsive donationimage"
                        src={item.imagesrc}
                        alt={item.fund}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <h5 className="card-title">{item.fund}</h5>
                    </div>
                    {item.description}
                  </div>
                </div>
                <div className="card-footer text-muted">
                  <Link to="/donate" className="btn btn-info">
                    <i className="fa fa-heartbeat " />
                    <span className="menu-title">{" Donate Now"}</span>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="alert alert-transparent">
          <h4 className="alert-heading">OUR IMPACT</h4>
          <div className="row">
            {this.props.impact.map((item, index) => {
              return (
                <div
                  className="margin1rem card col-md fontblack colorblue  alertbackground" //cardbackgroundimage
                  key={index}
                >
                  {item.count > 0 && (
                    <div className="card-header">{item.count}</div>
                  )}
                  <div className="card-body">
                    <img
                      src={item.imgsrc}
                      alt={item.heading}
                      className="impactimage impactbackgroundimage"
                    />
                    <h6 className="card-title">{item.heading}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="alert alert-dark-blue">
          <h4 className="alert-heading">Awareness at community levels</h4>
        </div>
        <hr></hr>
        <div className="alert alert-orange">
          <span>
            {" "}
            <h5>GIVE. ADVOCATE. VOLUNTEER</h5>{" "}
          </span>
        </div>
        <div className="alert alert-dark-blue">
          <span>
            {" "}
            <h5>
              WE STAND <b>UNITED</b>, EVERY STEP OF THE <b>WAY</b>
            </h5>{" "}
          </span>
        </div>

        {/* <div className="row padding1rem">
          <Link to="/donate" className="btn btn-info">
            <i className="{menu.iconclassName}" />
            <span className="menu-title">Donate Now</span>
          </Link>
        </div> */}
      </div>
    );
  }
}

function mapStateToProps({ covidInfo, coviddonors: { donors }, impact }) {
  return { covidInfo, donors, impact };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestCovidInfo: () => dispatch(actions.getLatestCovidInfo()),
    payment: (params) => dispatch(actions.payment(params)),
    getDonorList: () => dispatch(actions.getDonors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
