import axios from "axios";
import * as actions from "./types";

//import randomstring from "randomstring";

export const _getLatestCovidInfo = (data) => {
  console.log("_getLatestCovidInfo",data);
  return {
    type: actions.GET_COVID_INFO,
    data,
  };
};

export const _getLatestDonors = (data) => {
  return {
    type: actions.GET_DONORS,
    data,
  };
};

export const _getImpactList = (data) => {
  return {
    type: actions.GET_IMPACT,
    data,
  };
};

export const getLatestCovidInfo = (data) => async (dispatch) => {
  await axios({
    method: "get",
    url: "https://api.rootnet.in/covid19-in/stats/latest",
  })
    .then((res) => {
      if (res.data) {
        try {
          dispatch(_getLatestCovidInfo(res.data));
          // dispatch({
          //   type: actions.GET_COVID_INFO,
          //   data:res.data,
          // })
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getDonors = (data) => async (dispatch) => {
  let wurl = "https://apiqa.controla.in/payment/coviddonors";
  //let wurl = "https://localhost:5000/payment/coviddonors";
  await axios({
    method: "get",
    url: wurl,
  })
    .then((res) => {
      if (res.data) {
        console.log(res.data.data);
        try {
          dispatch(_getLatestDonors(res.data.data[0]));
         dispatch(_getImpactList(res.data.data[1]));
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const payment = (obj) => async (dispatch) => {
  let wurl = "https://apiqa.controla.in/payment/payCOVIDDonation";
  //let wurl = "https://localhost:5000/payment/payCOVIDDonation";
  await axios({
    method: "post",
    url: wurl,
    data: obj,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        console.log(res.data);
        try {
          var obj = {};
          obj.hash = res.data.params.hash;
          obj.amount = parseFloat(res.data.params.amount);
          obj = res.data.params;
          launchBOLT(obj, res.data);
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

function launchBOLT(payParams, otherdata) {
  let bolt = window.bolt;
  //let a = payParams;

  sessionStorage.setItem("paymentParams", payParams);
  bolt.launch(
    {
      hash: payParams.hash,
      txnid: payParams.txnid.toString(),
      key: payParams.key,
      amount: payParams.amount,
      firstname: payParams.firstname,
      email: payParams.email,
      phone: payParams.phone,
      productinfo: payParams.productinfo,
      surl: payParams.surl,
      furl: payParams.furl,
      lastname: payParams.lastname,
      curl: payParams.curl,
      address1: payParams.address1,
      address2: payParams.address2,
      city: payParams.city,
      state: payParams.state,
      country: payParams.country,
      zipcode: payParams.zipcode,
      udf1: payParams.udf1,
      udf2: payParams.udf2,
      udf3: payParams.udf3,
      udf4: payParams.udf4,
      // udf5: payParams.vpc_MerchTxnRef,
      udf5: payParams.udf5,
      pg: payParams.pg,
    },
    {
      responseHandler: async function (BOLT) {
        console.log(BOLT.response);
        if (BOLT.response.txnStatus.toUpperCase() !== "CANCEL") {
          // let wurl = "https://localhost:5000/payment/PaymentReceiptCovid";
          let wurl = "https://apiqa.controla.in/payment/PaymentReceiptCovid";
          await axios({
            method: "post",
            url: wurl,
            data: BOLT.response,
          }).then((res) => {
            console.log(res);
            // var queryString = "provider=PAYU&";

            // Object.entries(BOLT.response).forEach(([key, value]) => {
            //   queryString += key + "=" + value + "&";
            // });
            // queryString = queryString.slice(0, -1);

            window.location.href = res.data;
          });
        } else if (BOLT.response.txnStatus.toUpperCase() === "CANCEL") {
          console.log(BOLT.response);

          //   await axios({
          //     method: "post",
          //     url: keys.ServicePath + "/api/DeleteLicenceKey",
          //     data: a
          //   }).then(res=>{
          //     console.log(res);
          //   });
          //   //DeleteLicenceKey
        }
      },
      catchException: function (BOLT) {
        console.log(BOLT);
        // alert(BOLT);
      },
    }
  );
}


export const _insert = (data) => {
  return {
    type: actions.INSERT,
    data,
  };
};
export const _artworks = (data) => {
  return {
    type: actions.GET_ARTWORKS,
    data,
  };
};
export const insertartwork = (obj) => async (dispatch) => {
  let data = {
    obj,
  };
  let wurl = "https://apiqa.controla.in/payment/insertcovidartworks";
  //let wurl = "https://localhost:5000/payment/insertcovidartworks";
  await axios({
    method: "post",
    url: wurl,
    data,
  })
    .then((res) => {
     
      if (res.data) {
        
        
        dispatch(_insert(res.data.data));
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getartworks = () => async (dispatch) => {
 
  let wurl = "https://apiqa.controla.in/payment/getartworks";
  //let wurl = "https://localhost:5000/payment/getartworks";
  await axios({
    method: "get",
    url: wurl
  })
    .then((res) => {
     
      if (res.data) {
        
        
        dispatch(_artworks(res.data.data));
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const _getdonors = (data) => {
  return {
    type: actions.GET_DONOR_LIST,
    data,
  };
};
export const getdonors = () => async (dispatch) => {
 
  let wurl = "https://apiqa.controla.in/payment/getcovid19donors";
  //let wurl = "https://localhost:5000/payment/getcovid19donors";
  await axios({
    method: "get",
    url: wurl
  })
    .then((res) => {
     
      if (res.data) {
        
        
        dispatch(_getdonors(res.data.data));
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};


export const _download = (data) => {
  return {
    type: actions.GET_DOWNLOADS,
    data,
  };
};
export const download = (today) => async (dispatch) => {
 
  let wurl = `https://apiqa.controla.in/payment/download?today=${today}`;
  //let wurl = "https://localhost:5000/payment/getcovid19donors";
  await axios({
    method: "get",
    url: wurl
  })
    .then((res) => {
     
      if (res.data) {       
        
        dispatch(_download(res.data.data));
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getdownload = () => async (dispatch) => {
 
  let wurl = "https://apiqa.controla.in/payment/getdownload";
  //let wurl = "https://localhost:5000/payment/getcovid19donors";
  await axios({
    method: "get",
    url: wurl
  })
    .then((res) => {
     
      if (res.data) {       
        
        dispatch(_download(res.data.data));
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};
