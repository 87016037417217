import React, { Component } from "react";
import MetaTags from "react-meta-tags";
//import { Carousel } from "react-responsive-carousel";
import ReactBootstrapCarousel from "react-bootstrap-carousel";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";

import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
class CovidArtWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datetoopen: new Date(2020, 5, 19, 23, 59, 59),
      todatdate: new Date(),
    };
  }
  componentDidMount() {
    console.log(this.state.todatdate, this.state.datetoopen);
    this.props.getartwork();
  }

  render() {

    console.log(document.getElementsByTagName("META"));
    return (
      <div className="container-fluid container-padding " >
        <MetaTags>
          <title>Fight COVID-19 Yoga</title>
          <meta
            id="meta-description"
            name="description"
            content="Fight against covid-19, express yourself in form of art. Invitation to all age groups and professionals"
          />
          <meta
            id="og-title"
            property="og:title"
            content="Fight COVID-19 Yoga"
          />
          <meta
            id="og-image"
            property="og:image"
            content="http://donation.unitedwaybaroda.org/resources/images/artworkbanner_1.jpg"
          />
          <meta property="og:image:width" content="1600" />
          <meta property="og:image:height" content="900" />
        </MetaTags>
        <div className="row">
          <div className="col-md-12 ">
            <div className="banner h-100 d-inline-block">
              <span className="align-middle artheader">
                {""}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          {this.state.todatdate > this.state.datetoopen && (
            <div className="col-md-4">
              <Link to="/register-artwork" className="btn btn-info btn-block">
                <i className="fa fa-sign-in " />
                <span className="menu-title">{" UPLOAD YOUR XMAS CAMPAING"}</span>
              </Link>
            </div>
          )}
          {this.state.todatdate <= this.state.datetoopen && (
            <div className="col-md-4">
              {/* <Link to="/register-artwork" className="btn btn-info btn-block">
              <i className="fa fa-sign-in " />
              <span className="menu-title">{" UPLOAD YOUR YOGA PHOTO"}</span>
            </Link> */}
              <button className="btn btn-info btn-block" disabled={true}>
                <i className="fa fa-sign-in " />
                <span className="menu-title">{" UPLOAD YOUR XMAS CAMPAING"}</span>
              </button>
            </div>
          )}
          <div className="col-md-3">
            <Link to="/artgallery" className="btn btn-info btn-block">
              <i className="fa fa-picture-o" />
              <span className="menu-title">{"  Xmas Campaing Gallery"}</span>
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {this.state.todatdate <= this.state.datetoopen && (
              <img
                src={"../resources/images/poster_1.jpg"}
                alt="poster"
                className="height500"
              />
            )}
            <ReactBootstrapCarousel
              animation={true}
              autoplay={true}
              slideshowSpeed={5000}
              defaultActiveIndex={0}
              // leftIcon={this.state.leftIcon}
              // rightIcon={this.state.rightIcon}
              // onSelect={this._onSelect}
              ref={this.slider}
              version={4}
            >
              {this.props.artworks.map((item, i) => {
                return (
                  <div style={{ height: 600 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={"https://apiqa.controla.in/" + item.imagepath}
                      alt={item.name}
                      className="d-block w-100"
                    />
                    <div className="carousel-caption">{item.name}</div>
                  </div>
                );
              })}
            </ReactBootstrapCarousel>

            {/* <Carousel
              showArrows={true}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              useKeyboardArrows={true}
              showIndicators={false}
              stopOnHover={true}
              //dynamicHeight={true}
            >
              {this.props.artworks.map((item) => {
                return (
                  <div>
                    <img
                      src={"https://apiqa.controla.in/" + item.imagepath}
                      alt={item.imagepath}
                      className="img-fluid"
                    />
                    <p className="legend">{item.name}</p>
                  </div>
                  
                );
              })}
            </Carousel> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ artworks }) {
  return { artworks };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getartwork: () => dispatch(actions.getartworks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CovidArtWork);
