import React, { Component } from "react";
//import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
import Select from "react-select";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import FilePondPluginFileRename from "filepond-plugin-file-rename";

import MetaTags from "react-meta-tags";
import Timer from "./timer";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const server = {
  url: "https://apiqa.controla.in/payment/",
  //url: "https://localhost:5000/payment/",
  process: "uploadimage",
  revert: null,
};

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileRename
);

class RegisterArtWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderOption: [
        { label: "MALE", value: "M" },
        { label: "FEMALE", value: "F" },
        { label: "OTHERS", value: "O" },
      ],
      YesNoOption: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      IdOptions: [
        { label: "Aadhar No", value: "Aadhar" },
        { label: "Passport", value: "Passport" },
        { label: "Driving License", value: "Driving Lic." },
      ],
      ageGroupOption: [
        { label: "6-12", value: "0" },
        { label: "13-20", value: "1" },
        { label: "Above 20", value: "2" },
      ],
      files: [],
      disabled: true,
      message: "",
      eventDate: new Date("January 01, 2021"),
      eventEndDate: new Date("February 11, 2021 23:59:00"),
      today: new Date(),
    };
  }
  // componentDidMount() {
  //   console.log(
  //     "EVENT DATE",
  //     this.state.eventDate.getTime(),
  //     "CURRENT DATE",
  //     new Date().getTime(),
  //     new Date().getTime() < new Date(this.state.eventDate).getTime()
  //   );
  // }

  componentDidMount() {
    this.props.getartwork();
  }

  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onDateChange = (e) => {
    console.log(e.target.value);
  };
  onDDChange = (selectedOption) => {
    this.setState({ gender: selectedOption.value, selectedOption });
  };
  onArtistDDChange = (artistselectedOption) => {
    this.setState({
      isprofessionalartist: parseInt(artistselectedOption.value),
      artistselectedOption,
    });
  };
  onIDChange = (idselectedOption) => {
    this.setState({
      idproof: parseInt(idselectedOption.value),
      idselectedOption,
    });
  };
  onageDDChange = (ageselectedOption) => {
    this.setState({
      age: parseInt(ageselectedOption.value),
      ageselectedOption,
    });
  };
  onSubmit = async () => {
    let path = [];
    try {
      this.pond.getFiles().map((item) => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.files[0].name);
    }
    let obj = {
      fullname: this.state.fullname,
      //age: this.state.age,
      // age: 0,
      email: this.state.email,
      phone: this.state.phone,
      activitydate: this.state.activitydate,
      // gender: this.state.gender,
      // isprofessionalartist: 0,
      // idproof: this.state.idproof,
      // idnumber: this.state.idnumber,
      imagepath: path[0],
      description: this.state.description,
    };

    await this.props.insertartwork(obj);
    this.clear();
  };
  clear() {
    var pond_ids = [];

    if (this.pond.getFiles().length !== 0) {
      // "pond" is an object, created by FilePond.create
      this.pond.getFiles().forEach(function (file) {
        pond_ids.push(file.id);
      });
    }

    this.pond.removeFiles(pond_ids);
    this.setState(
      {
        fullname: "",
        age: "",
        email: "",
        phone: "",
        gender: "",
        isprofessionalartist: "",
        imagepath: "",
        files: [],
        selectedOption: null,
        artistselectedOption: null,
        disabled: true,
        idproof: "",
        idnumber: "",
        idselectedOption: null,
        description: "",
        message: "Photo submitted successfully",
      },
      () => {
        this.setState((prevState) => ({ refresh: !prevState.refresh }));
      }
    );

    setTimeout(() => {
      this.setState({ message: "" });
    }, 2000);
  }
  render() {
    return (
      <div className="container-fluid" style={{ padding: 20 }}>
        <MetaTags>
          <title>Philanthropic Month</title>
          <meta
            id="meta-description"
            name="description"
            content="Fight against covid-19, Manavseva."
          />
          <meta id="og-title" property="og:title" content="Manavseva" />
          <meta
            id="og-image"
            property="og:image"
            content="http://donation.unitedwaybaroda.org/resources/images/manavseva-min.jpg"
          />
          <meta property="og:image:width" content="1600" />
          <meta property="og:image:height" content="900" />
        </MetaTags>

        <div className="row">
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-md-12 alignCenter">
                <img
                  style={{ width: 350 }}
                  src={"../resources/images/manavseva.jpg"}
                  alt="poster"
                  //className="height500"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Carousel
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  dynamicHeight={true}
                  thumbWidth={50}
                >
                  {this.props.artworks.map((item) => {
                    return (
                      <div>
                        <img
                          src={"https://apiqa.controla.in/" + item.imagepath}
                        />
                        <p className="legend">{item.description}</p>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          {new Date() >= this.state.eventDate &&
            new Date() <= this.state.eventEndDate && (
              <div className="col-md-6">
                <form className="form">
                  <div className="form">
                    <div class="form-group">
                      <label for="fullname">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="fullname"
                        onChange={this.onChange}
                        required
                        value={this.state.fullname}
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={this.onChange}
                        required
                        value={this.state.email}
                      />
                    </div>
                    <div class="form-group">
                      <label for="phone">Phone No.</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={this.onChange}
                        required
                        value={this.state.phone}
                      />
                    </div>
                    <div class="form-group">
                      <label for="phone">
                        Upload your activity's photograph
                      </label>
                      <FilePond
                        name={"file"}
                        ref={(ref) => (this.pond = ref)}
                        allowMultiple={false}
                        accept="image/png, image/jpeg, image/gif"
                        server={server}
                        oninit={() => this.handleInit()}
                        onupdatefiles={(fileItems) => {
                          this.setState({
                            files: fileItems.map((fileItem) => fileItem.file),
                          });
                        }}
                        fileValidateTypeLabelExpectedTypesMap={{
                          "image/jpeg": ".jpg",
                        }}
                        labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                        allowFileSizeValidation={true}
                        maxTotalFileSize={10485760}
                        onprocessfile={(error, file) => {
                          //console.log(file);
                          if (error) {
                            return;
                          }
                          this.setState({ disabled: false });
                        }}
                        allowFileRename={true}
                        fileRenameFunction={(file) => {
                          let newName = `${this.state.fullname}${file.extension}`;
                          return newName;
                        }}
                        //onremovefile={(file) => {}}
                        //allowImagePreview={false}
                        //imagePreviewHeight={100}
                      ></FilePond>
                    </div>
                    <div class="form-group">
                      <label for="description">Conditions</label>
                      <ul>
                        <li style={{ fontWeight: "bold", fontSize: "large" }}>
                          {`Valid for residents of India only`}
                        </li>
                        <li>{`One entry for one person`}</li>
                        <li>
                          {`Clear, Visible photo of beneficiary and philanthropist`}
                        </li>
                      </ul>
                    </div>
                    <div class="form-group">
                      <label for="description">Date of Philanthropy Work</label>
                      <input
                        type="date"
                        className="form-control"
                        name="activitydate"
                        onChange={this.onChange}
                        min={"2021-01-01"}
                        max={"2021-02-08"}
                        required
                        value={this.state.activitydate}
                      />
                    </div>
                    <div class="form-group">
                      <label for="description">
                        Describe the philanthropic activity done in the
                        photograph submitted by you.
                      </label>
                      <textarea
                        className="form-control"
                        name="description"
                        onChange={this.onChange}
                        rows={4}
                        required
                        value={this.state.description}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <button
                          type="button"
                          className="btn btn-success"
                          disabled={this.state.disabled}
                          onClick={this.onSubmit}
                        >
                          Post your photo
                        </button>
                      </div>
                      <div className="col-md">
                        <Link to="/artgallery" className="btn btn-success">
                          View Gallery/Uploaded Photos
                        </Link>
                      </div>
                    </div>

                    {/* {this.props.insert.insert.includes("success") && <div className="alert alert-success">{this.props.insert}</div>} */}
                  </div>
                </form>
                {this.state.message !== "" && (
                  <div className="alert alert-success">
                    {this.state.message}
                  </div>
                )}
              </div>
            )}
          {(new Date().getTime() < this.state.eventDate.getTime() ||
            new Date().getTime() > this.state.eventEndDate.getTime()) && (
            <div class="col-md-6">
              <div className="timer">
                <div className="App-title">
                  <b>Registration Closed</b>
                </div>
              </div>
              {/* <Timer
                key={this.state.eventDate}
                eventName={"Philanthropic Month"}
                eventDate={this.state.eventDate}
              /> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ insert, artworks }) {
  return { insert, artworks };
}

const mapDispatchToProps = (dispatch) => {
  return {
    insertartwork: (obj) => dispatch(actions.insertartwork(obj)),
    getartwork: () => dispatch(actions.getartworks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterArtWork);
