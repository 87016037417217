import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
//import dateFormat from "dateformat";
import randomstring from "randomstring";
import Select from "react-select";
import data from "../resources/currency.json";

class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: [
        { amount: 500, selected: 1 },
        { amount: 1000, selected: 0 },
        { amount: 2000, selected: 0 },
        { amount: 5000, selected: 0 },
        { amount: 10000, selected: 0 },
        { amount: 25000, selected: 0 },
        { amount: 50000, selected: 0 },
      ],
      showForm: true,
      donor: "Indian",
      selectedOption: {
        label: "INDIA",
        currency: "Indian Rupee",
        value: "INR",
      },
    };
  }

  selectAmount(item) {
    console.log(item);
    let tmp = [...this.state.amount.map((obj) => ({ ...obj, selected: 0 }))];

    tmp = tmp.map((el) =>
      el.amount === item.amount ? { ...el, selected: 1 } : el
    );
    this.setState((prev) => ({
      amount: tmp,
      selectedAmount: item.amount,
      refresh: !prev.refresh,
    }));
  }

  onchange = (e) => {
    console.log(e.target);
    this.setState({ selectedAmount: e.target.value });
    let tmp = [...this.state.amount.map((obj) => ({ ...obj, selected: 0 }))];

    this.setState((prev) => ({
      amount: tmp,
      //  selectedAmount: e.target.value,
      refresh: !prev.refresh,
    }));
  };

  onInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onNextClick = () => this.setState({ showForm: true });

  paymentClick = () => {
    var batch = randomstring.generate({ length: 9, charset: "numeric" });
    const formdata = {
      name: this.state.firstname + " " + this.state.lastname,
      batchno: batch,
      contact: this.state.contact,
      email: this.state.email,
    };

    // await this.props.generatelicencekeys(formdata);

    let orderInfo = {};
    let payRequest = {};
    orderInfo = {
      name: this.state.firstname + " " + this.state.lastname,
      panno: this.state.panno,
      email: this.state.email,
      contact: this.state.contact,
      product: "COVID-19 Donation",
      country:
        this.state.donor === "India"
          ? "India"
          : this.state.selectedOption.label,
    };

    payRequest = {
      id: 0,
      vpc_Amount: parseFloat(this.state.selectedAmount),
      //vpc_Amount:1,
      vpc_MerchTxnRef:
        formdata.name + "|" + formdata.contact + "|" + orderInfo.product,
      vpc_OrderInfo: JSON.stringify(orderInfo),
      vpc_Version: 1,
      vpc_OrderInfo1: JSON.stringify(orderInfo),
      vpc_firstname: this.state.firstname,
      vpc_email: this.state.email,
      vpc_phone: this.state.contact,
      vpc_udf1: "COVID-19 Donation",
      vpc_udf2: this.state.panno,
      vpc_udf3: batch,
      vpc_udf4: this.state.contact,
      vpc_udf5: "",
      vpc_udf6: "2",
      vpc_lastName: this.state.lastname,
      vpc_address: this.state.address,
      vpc_country:"India",
        // this.state.donor === "India"
        //   ? "India"
        //   : this.state.selectedOption.label,
      formdata,
    };

    this.props.payment(payRequest);
  };

  radioChange(item) {
    console.log(item);
    this.setState({ donor: item }, () => {
      this.setState((prev) => ({ refresh: !prev.refresh }));
    });
  }
  onChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="alert alert-blue">
          <span>
            {" "}
            <h5>Campaign: Fight Against COVID-19</h5>{" "}
          </span>
          <span>
            {" "}
            <h5>Project: IMMEDIATE REQUIREMENTS- HEALTH WORKERS</h5>{" "}
          </span>
          <span>
            {" "}
            <h5>Beneficiary NGO: United Way Baroda (FCRA Registered)</h5>{" "}
          </span>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value={this.state.donor}
                    onChange={() => this.radioChange("Indian")}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Indian Donor
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value={this.state.donor}
                    onChange={() => this.radioChange("International")}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    International Donor
                  </label>
                </div>
              </div>
              <div className="col-md">
                {this.state.donor === "International" && (
                  <Select
                    options={data}
                    onChange={this.onChange}
                    value={this.state.selectedOption}
                  />
                )}
              </div>
              {this.state.donor === "International" && (
                <div class="col-md">
                  <label class="sr-only" for="inlineFormInputGroup">
                    Amount to Donate
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        {this.state.selectedOption.value}
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Donation Amount"
                      aria-label="Custom Amount"
                      name="customamount"
                      aria-describedby="basic-addon1"
                      onChange={(e) =>
                        this.setState({ amounttoconvert: e.target.value })
                      }
                      value={this.state.amounttoconvert}
                    />
                    <small><a href={"https://www.xe.com/currencyconverter/convert/?Amount="+this.state.amounttoconvert+"&From="+this.state.selectedOption.value+"&To=INR"} target="_blank" rel="noopener noreferrer">{"Click here "}</a> {" to convert amount from any currency to INR using the Universal Currency Converter"}</small>
                  </div>
                </div>
              )}
            </div>

            <hr></hr>
            <div className="row padding1rem">
              {this.state.amount.map((item, index) => {
                return (
                  <button
                    className={
                      item.selected
                        ? "btn margin1rem btn-danger"
                        : "btn margin1rem btn-info"
                    }
                    key={index}
                    onClick={() => this.selectAmount(item)}
                  >
                    {item.amount}
                  </button>
                );
              })}
              <div class="col-auto">
                <label class="sr-only" for="inlineFormInputGroup">
                  Amount
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">INR</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Donation Amount"
                    aria-label="Custom Amount"
                    name="customamount"
                    aria-describedby="basic-addon1"
                    onChange={this.onchange}
                    value={this.state.selectedAmount}
                  />
                </div>
              </div>
            </div>
            {this.state.showForm && (
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label for="inputEmail4">
                      <span className="required">*</span>First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      name="firstname"
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="inputPassword4"><span className="required">*</span>{"Last Name"}</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="inputPassword4"
                      name="lastname"
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label for="email">
                      <span className="required">*</span>
                      {
                        "Email (Use official email id, if representing any organization)"
                      }
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="contact">
                      <span className="required">*</span>Contact #
                    </label>
                    <input
                      required
                      className="form-control"
                      id="contact"
                      name="contact"
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label for="PAN No">
                      <span className="required">*</span>Pan # (Passport # for International Donor)
                    </label>
                    <input
                      required
                      className="form-control"
                      id="panno"
                      name="panno"
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="address">
                      <span className="required">*</span>Address
                    </label>
                    <textarea
                      required
                      rows={2}
                      className="form-control"
                      id="address"
                      name="address"
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <b> Please Note*:</b>
                  <p>
                    <ul>
                      <li>Fields marked with <span class="required">*</span> are compulsory</li>
                      <li>
                        All donations received in India are eligible for tax
                        exemption under section 80G of Income Tax Act, India
                      </li>
                      <li>
                        Foreign contributions would not be eligible for
                        tax-deductions in their home countries
                      </li>
                      <li>
                        For Non Indian donors, only International Credit cards
                        are accepted. Foreign Netbanking and Debit card options
                        will not get accepted.
                      </li>
                      {/* <li>
                In order to avoid the 80G receipt from going into your spam
                folder, please add mail@unitedwaymumbai.org to your safe senders
                list
              </li> */}
                    </ul>
                  </p>
                </div>
                <div className="form-row">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={this.paymentClick}
                  >
                    DONATE NOW
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>

        {/* <div className="alert alert-warning">
        IMPORTANT: Clicking on the DONATE button below indicates that you read and agreed with our Terms and Conditions
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestCovidInfo: () => dispatch(actions.getLatestCovidInfo()),
    payment: (params) => dispatch(actions.payment(params)),
  };
};
export default connect(null, mapDispatchToProps)(Donate);
