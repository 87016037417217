import React, { useState, useCallback } from "react";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
//import { photos } from "./photos";

const CovidArtGallery = (photos) => {
  console.log(photos);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // const openLightbox = useCallback((event, {  index }) => {
  //   console.log(event,photo,index)
  //   setCurrentImage(index);
  //   setViewerIsOpen(true);
  // }, []);

  const openLightbox = useCallback((event, { photo,index }) => {
    //console.log(event,photo,index)
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  return (
    <>
      {/* <Gallery photos={photos.photos} onClick={openLightbox}/> */}
      <div className="row">
          
        {photos.photos.map((item, j) => {
          return (
             <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 imagegallery" onClick={()=>openLightbox(item,j)} >
              
                <img
                  src={"https://apiqa.controla.in/" + item.imagepath}
                  alt={item.description}
                  className="img-fluid img-thumbnail rounded"
                  //   href={"https://apiqa.controla.in/" + item.imagepath}
                />
                <p className="legend">{item.name}</p>
              
             </div>
          );
        })}
        
      </div>
      <ModalGateway>
      {console.log(photos.photos[currentImage]?.description)}
        {viewerIsOpen ? (
          
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              formatters={photos.photos[currentImage]?.description}
              views={photos.photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.description,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default CovidArtGallery;
