import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Dashboard from "./components/dashboard";
import DonationReceipt from "./components/paymentReceipt";
import Donate from "./components/donate";
import Art from "./components/covidartwork";
import RegisterArtWork from "./components/registerartwork";
import ArtGallery from "./components/artgallery";
import PrintReceipts from "./components/PrintReceipts";
import "./App.css";
import Download from "./components/download";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <div className=""> */}
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/donate" component={Donate} />
          <Route exact path="/donationreceipt" component={DonationReceipt} />

          <Route exact path="/art" component={Art} />
          {/* <Route exact path="/register-artwork" component={RegisterArtWork} /> */}
          <Route exact path="/manavseva" component={RegisterArtWork} />
          <Route exact path="/artgallery" component={ArtGallery} />
          <Route exact path="/print" component={PrintReceipts} />
          <Route exact path="/download" component={Download} />
        {/* </div> */}
      </BrowserRouter>
    );
  }
}
export default App;
