import { combineReducers } from "redux";
import {
  covidInfo,
  coviddonors,
  impact,
  insert,
  artworks,
  donorList,
  downloads,
} from "./covidDashboard";

export default combineReducers({
  covidInfo,
  coviddonors,
  impact,
  insert,
  artworks,
  donorList,
  downloads,
});
